<script lang="ts" setup>
import { get } from 'lodash-es';

import type { UiTableColumnProp } from '@/interfaces';

import TableCellDefault from '@/components/table/TableCellDefault.vue';

interface Props {
  column: UiTableColumnProp;
  row: any;
}

defineOptions({ name: 'TableCell' });

const props = defineProps<Props>();

const createProps = () => {
  const cellProps = props.column?.cell?.props || {};

  return Object.keys(cellProps).reduce(
    (acc, item) => ({
      ...acc,
      [item]:
        typeof cellProps[item] === 'string' && cellProps[item].includes('row.')
          ? get(props, cellProps[item])
          : cellProps[item],
    }),
    {},
  );
};

const render = () => {
  const { cell, key, styles } = props.column;

  if (!cell?.component) {
    let value;

    if (key.includes('row.')) {
      value = get(props, key);
    } else {
      value = props.row[key];
    }

    return h(TableCellDefault, {
      align: styles?.align ?? 'left',
      value,
      defaultValue: cell?.props?.defaultValue,
      typography: styles?.typography,
    });
  }

  return h(cell.component, { ...createProps() });
};
</script>

<template>
  <render />
</template>
