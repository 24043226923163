<script setup lang="ts">
import type { UiTableColumnProp } from '@/interfaces';

interface Props {
  columns: UiTableColumnProp[];
  rows: any;
  sorting?: string;
  loadingItems?: number;
  loading: boolean;
  page?: number;
  limit?: number;
  count?: number;
  isPageHeaderSticky?: boolean;
  hasError?: boolean;
}

defineOptions({
  name: 'Table',
});

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  loadingItems: 25,
  isPageHeaderSticky: false,
  hasError: false,
});

const emit = defineEmits<{
  (e: 'onSort', value: string): void;
  (e: 'onPaginate', page: number, limit: number): void;
  (e: 'onRowMouseEnter', row: any): void;
  (e: 'onRowMouseLeave', row: any): void;
  (e: 'onRowClick', row: any): void;
  (e: 'onCellClick', column: UiTableColumnProp, row: any): void;
  (e: 'onScrollFinish'): void;
}>();

const cn = useClassName('table');
const { isDesktop } = useBreakpoint();

const pagination = computed(() => {
  if (!props.page && !props.count) return null;

  return {
    page: props.page,
    limit: props.limit,
    count: props.count,
  };
});

const isHeadSticky = computed(() => isDesktop.value);

const handleSort = (value: string) => emit('onSort', value);
const handlePaginate = (page: number, limit: number) =>
  emit('onPaginate', page, limit);

const handleRowClick = (row: any) => emit('onRowClick', row);
const handleRowMouseEnter = (row: any) => emit('onRowMouseEnter', row);
const handleRowMouseLeave = (row: any) => emit('onRowMouseLeave', row);
const handleCellClick = (column: UiTableColumnProp, row: any) =>
  emit('onCellClick', column, row);

const handleScrollFinish = () => emit('onScrollFinish');

const tableRef = ref(null);
defineExpose({
  scrollIntoView: () => tableRef.value?.scrollIntoView(),
});
</script>

<template>
  <UiTable
    ref="tableRef"
    :columns="props.columns"
    :rows="props.rows"
    :sorting="props.sorting"
    :loadingItems="props.loadingItems"
    :loading="props.loading"
    :pagination="pagination"
    :head="{ isSticky: isHeadSticky }"
    needScroll
    :class="Boolean($slots['pagination-text']) && cn.b()"
    :hasError="props.hasError"
    @sort="handleSort"
    @paginate="handlePaginate"
    @click:row="handleRowClick"
    @mouseenter:row="handleRowMouseEnter"
    @mouseleave:row="handleRowMouseLeave"
    @click:cell="handleCellClick"
    @finish:scroll="handleScrollFinish"
  >
    <template v-if="$slots.toolbar" #toolbar>
      <slot name="toolbar" />
    </template>

    <template #cell-skeleton="{ column }">
      <TableCellSkeleton :column="column" />
    </template>

    <template #cell="{ column, row }">
      <TableCell :column="column" :row="row" />
    </template>

    <template #pagination-text>
      <div v-if="$slots['pagination-text']" :class="cn.e('pagination-text')">
        <slot name="pagination-text" />
      </div>
    </template>
  </UiTable>
</template>

<style lang="scss">
.table {
  &__pagination-text {
    width: 100%;
    padding: var(--spacing-06) 0 var(--spacing-04);
    border-bottom: 1px solid var(--color-secondary-200);
  }

  .ui-table__pagination {
    --ui-table-pagination-height: auto;

    .ui-table-pagination {
      flex-wrap: wrap;
    }
  }
}

@include breakpoint('sm') {
  .table {
    &__pagination-text {
      width: auto;
      padding: 0;
      border-bottom: none;
    }

    .ui-table__pagination {
      --ui-table-pagination-height: 72px;

      .ui-table-pagination {
        flex-wrap: nowrap;
      }
    }
  }
}
</style>
