import {
  CounterpartyBusinessType,
  CounterpartyType,
  FilterType,
} from '@/enums';
import type { CounterpartiesFilters, FiltersConfig } from '@/interfaces';

export const CounterpartyBusinessTypesMap = {
  [CounterpartyBusinessType.Business]: {
    title: 'Business',
  },
  [CounterpartyBusinessType.Individual]: {
    title: 'Individual',
  },
} as const;

export const CounterpartyTypesMap = {
  [CounterpartyType.Customer]: {
    title: 'Customer',
    isFilterShow: true,
    filterOrder: 0,
  },
  [CounterpartyType.Vendor]: {
    title: 'Vendor',
    isFilterShow: true,
    filterOrder: 1,
  },
  [CounterpartyType.Merchant]: {
    title: 'Merchant',
    isFilterShow: false,
    filterOrder: 2,
  },
} as const;

export const CounterpartiesFiltersConfig: FiltersConfig<CounterpartiesFilters> =
  {
    types: {
      type: FilterType.Array,
      possibleValues: Object.values(CounterpartyType),
    },
    periodName: {
      type: FilterType.String,
    },
    periodDates: {
      type: FilterType.Array,
    },
  } as const;
