import { CORE_ACCOUNT_NAVIGATION } from '@/data';
import type { Page } from '@/interfaces';

export const COMPANY_ACCOUNT_BUDGETS_PAGE: Page = {
  name: 'company-account-budgets-page',
  metaInfo: {
    title: 'Account budgets',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
};

export const COMPANY_ACCOUNT_TRANSACTIONS_PAGE: Page = {
  name: 'company-account-transactions-page',
  metaInfo: {
    title: 'Account transactions',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
};

export const COMPANY_ACCOUNT_CARDS_PAGE: Page = {
  name: 'company-account-cards-page',
  metaInfo: {
    title: 'Account cards',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
};

export const COMPANY_ACCOUNT_INDEX_PAGE: Page = {
  name: 'company-account-index-page',
  metaInfo: {
    title: 'Account',
  },
  navigation: CORE_ACCOUNT_NAVIGATION,
  tabs: [
    {
      label: 'Transactions',
      value: COMPANY_ACCOUNT_TRANSACTIONS_PAGE.name,
      disabled: false,
    },
    {
      label: 'Budgets',
      value: COMPANY_ACCOUNT_BUDGETS_PAGE.name,
      disabled: false,
    },
    {
      label: 'Cards',
      value: COMPANY_ACCOUNT_CARDS_PAGE.name,
      disabled: false,
    },
  ],
};
