<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core';

import { useStagingManagement } from '@/composables';

defineOptions({ name: 'Banners' });

const root = document.querySelector(':root');

const banners = ref(null);

const { isUrlCustom: isApiBaseUrlCustom } = useStagingManagement();

useResizeObserver(banners, entries => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  root?.style?.setProperty('--banners-height', `${height}px`);
});
</script>

<template>
  <div ref="banners" class="banners">
    <StagingManagementBanner v-if="isApiBaseUrlCustom" />
  </div>
</template>
