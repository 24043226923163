import type { ResponseError } from '@karta.io/utils';

import {
  type CompanyProviderOutcomePaymentMethod,
  Currency,
  MoveMoneyInternalSendFundsStep,
} from '@/enums';
import type {
  CompanyAccount,
  Counterparty,
  CounterpartyPaymentAccount,
} from '@/interfaces';

interface State {
  counterparty?: Counterparty;
  counterpartyPaymentAccount?: CounterpartyPaymentAccount;
  companyAccount?: CompanyAccount;

  type?: CompanyProviderOutcomePaymentMethod;
  amountValue?: string;
  description?: string;

  step: MoveMoneyInternalSendFundsStep;
  isLoading: boolean;
  errors?: ResponseError['data'];
}

export const useMoveMoneySendFundsStore = defineStore(
  'moveMoneySendFundsStore',
  {
    state: (): State => ({
      counterparty: undefined,
      counterpartyPaymentAccount: undefined,
      type: undefined,
      companyAccount: undefined,

      amountValue: undefined,
      description: undefined,

      step: MoveMoneyInternalSendFundsStep.First,
      isLoading: false,
      errors: undefined,
    }),
    getters: {
      amountCurrency: (state: State): Currency =>
        state.companyAccount?.currency || Currency.Usd,
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMoveMoneySendFundsStore, import.meta.hot),
  );
}
