import device from 'current-device';

import { useEnv } from '@/composables';
import type { CoreAppConfig } from '@/interfaces';

interface State {
  isMobileMenuOpen: boolean;
  appVersion: string;
  isDesktopDevice: boolean;
  getStartedSalesSocialsLinks: CoreAppConfig['data']['getStartedSalesSocialsLinks'];
  contactUsLink: CoreAppConfig['data']['contactUsLink'];
  showBillingPage: CoreAppConfig['data']['showBillingPage'];
  isTechWorks: CoreAppConfig['data']['isTechWorks'];
  techWorksKey: CoreAppConfig['data']['techWorksKey'];
}

const { appVersion } = useEnv();

export const useCommonStore = defineStore('commonStore', {
  state: (): State => ({
    isMobileMenuOpen: false,
    appVersion,
    getStartedSalesSocialsLinks: [],
    contactUsLink: '',
    showBillingPage: false,
    isTechWorks: false,
    techWorksKey: '',
    /**
     * @todo Сделать более "реактивным" (хотя бы отслеживать смену брейкпоинта)
     */
    isDesktopDevice: device.desktop(),
  }),
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot));
}
