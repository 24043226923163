import { type Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import type { Nullable } from '@karta.io/ui-components';

import { useCoreStore } from '@/stores';
import { useEnv } from '@/composables';
import { CompanyUserRole } from '@/enums';

const { segmentKey, segmentProxyApiUrl, segmentProxyCdnUrl } = useEnv();
let analytics: Nullable<Analytics> = null;
let isAliasCalled = false;

export function useAnalytics() {
  // нельзя сразу тянуть стор,
  // так как он не успевает установиться при использовании initAnalytics

  let coreStore: ReturnType<typeof useCoreStore> | null = null;

  const isReady = () => Boolean(analytics?.initialized);

  const initAnalytics = async () => {
    if (!segmentKey) return;
    try {
      const response = await AnalyticsBrowser.load(
        {
          writeKey: segmentKey,
          ...(segmentProxyCdnUrl && { cdnURL: segmentProxyCdnUrl }),
        },
        {
          ...(segmentProxyApiUrl && {
            integrations: {
              'Segment.io': {
                apiHost: segmentProxyApiUrl,
                protocol: 'https',
              },
            },
          }),
        },
      );

      analytics = response[0];
    } catch (error) {
      console.error(error);
    }
  };

  const identify = async (traits?: Record<string, any>) => {
    if (!analytics) return;
    if (!coreStore) coreStore = useCoreStore();
    if (!coreStore.id) return;

    if (!analytics?.user().id() && !isAliasCalled) {
      isAliasCalled = true;
      await analytics.alias(String(coreStore.id));
    }

    analytics?.identify(
      String(coreStore.id),
      {
        email: coreStore.email,
        ...traits,
      },
      {
        integrations: {
          HubSpot: coreStore.currentCompanyUser?.role === CompanyUserRole.Owner,
        },
      },
    );
  };

  const group = (id?: string | number, traits?: Record<string, any>): void => {
    if (!analytics) return;
    if (!analytics.user().id()) identify();
    if (!coreStore) coreStore = useCoreStore();

    const groupId = id ?? coreStore.currentCompany?.id;
    const groupName = id ?? coreStore.currentCompany?.name;
    if (!groupId) return;

    analytics.group(String(groupId), {
      name: groupName,
      ...traits,
    });
  };

  const track = (eventName: string, props?: Record<string, any>): void => {
    if (!analytics) return;
    if (!analytics.user().id()) identify();
    if (!coreStore) coreStore = useCoreStore();

    analytics.track(eventName, {
      userId: String(coreStore.id),
      ...(coreStore.currentCompany?.id && {
        companyId: coreStore.currentCompany?.id,
      }),
      ...props,
    });
  };

  const page = (name?: string, props?: Record<string, any>): void => {
    if (!analytics) return;
    if (!analytics.user().id()) identify();
    if (!coreStore) coreStore = useCoreStore();

    analytics.page(name, {
      userId: String(coreStore.id),
      ...props,
    });
  };

  return {
    isReady,
    identify,
    group,
    track,
    page,
    reset: () => analytics?.reset(),

    initAnalytics,
  };
}
