import {
  CompanyPlanGroup,
  CompanyPlanKey,
  CompanyProviderPaymentService,
} from '@/enums';
import type { CompanyPlanKeys } from '@/interfaces';

export const CompanyPlanKeysMap: CompanyPlanKeys = {
  // Stripe Treasury
  [CompanyPlanKey.StripeInboundAchFee]: {
    title: 'ACH Incoming',
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.OutboundAchFee]: {
    title: 'ACH Outgoing',
    group: CompanyPlanGroup.Treasury,
    order: 2,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeInboundWireFee]: {
    title: 'Wire Domestic Incoming',
    group: CompanyPlanGroup.Treasury,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.OutboundWireFee]: {
    title: 'Wire Domestic Outgoing',
    group: CompanyPlanGroup.Treasury,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.InboundCbFee]: {
    title: 'Crypto deposit',
    group: CompanyPlanGroup.Treasury,
    order: 5,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },

  // Stripe Cards
  [CompanyPlanKey.MonthlyCardIssuingLimit]: {
    title: 'Monthly free virtual cards',
    group: CompanyPlanGroup.Cards,
    order: 6,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeCardIssuingFee]: {
    title: 'Issue additional virtual card',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    order: 7,
  },
  [CompanyPlanKey.StripeInternationalPaymentFee]: {
    title: 'Cross-border transaction',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    order: 8,
  },
  [CompanyPlanKey.StripeCurrencyExchangeFee]: {
    title: 'Foreign exchange',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    order: 9,
  },

  // Stripe Invoices
  [CompanyPlanKey.InvoicePaidFee]: {
    title: 'Fee per paid invoice',
    group: CompanyPlanGroup.Invoices,
    order: 10,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },

  // Airwallex Treasury
  [CompanyPlanKey.AirwallexInboundAchFee]: {
    title: 'ACH Incoming',
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInboundWireFee]: {
    title: 'Fedwire Incoming',
    group: CompanyPlanGroup.Treasury,
    order: 2,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInboundSwiftFee]: {
    title: 'SWIFT Incoming',
    group: CompanyPlanGroup.Treasury,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInboundCbFee]: {
    title: 'Crypto deposit',
    group: CompanyPlanGroup.Treasury,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },

  // Airwallex Cards
  [CompanyPlanKey.AirwallexCardIssuingFee]: {
    title: 'Issue additional virtual card',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 5,
  },
  [CompanyPlanKey.AirwallexInternationalPaymentFee]: {
    title: 'Cross-border transaction',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 6,
  },
  [CompanyPlanKey.AirwallexCurrencyExchangeFee]: {
    title: 'Foreign exchange',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 7,
  },
  [CompanyPlanKey.AirwallexDomesticFee]: {
    title: 'Domestic transactions under $10.00',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 8,
  },

  // Marqeta Cards

  [CompanyPlanKey.MarqetaCardIssuingFee]: {
    title: 'Issue additional virtual card',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    order: 1,
  },
  [CompanyPlanKey.MarqetaInternationalPaymentFee]: {
    title: 'Cross-border fee',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    order: 2,
  },
  [CompanyPlanKey.MarqetaCurrencyExchangeFee]: {
    title: 'Foreign exchange',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    order: 3,
  },

  // Amaiz Treasury
  [CompanyPlanKey.AmaizEurInboundCbFee]: {
    title: 'Crypto deposit',
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
  },

  // Amaiz Cards
  [CompanyPlanKey.AmaizEurCardIssuingFee]: {
    title: 'Issue additional virtual card',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    order: 2,
  },
  [CompanyPlanKey.AmaizEurInternationalPaymentFee]: {
    title: 'Cross-border transaction',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    order: 3,
  },
  [CompanyPlanKey.AmaizEurExchangeFee]: {
    title: 'Foreign exchange',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    order: 4,
  },

  // Spenxy Treasury
  [CompanyPlanKey.SpenxyInboundCbFee]: {
    title: 'Crypto deposit',
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
  },

  // Spenxy Cards
  [CompanyPlanKey.SpenxyCardIssuingFee]: {
    title: 'Issue additional virtual card',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    order: 2,
  },
  [CompanyPlanKey.SpenxyInternationalPaymentFee]: {
    title: 'Cross-border transaction',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    order: 3,
  },
  [CompanyPlanKey.SpenxyCurrencyExchangeFee]: {
    title: 'Foreign exchange',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    order: 4,
  },
} as const;

export const CompanyPlanGroupsMap = {
  [CompanyPlanGroup.Treasury]: {
    title: 'Treasury',
    order: 1,
    skeletonRows: 5,
  },
  [CompanyPlanGroup.Cards]: {
    title: 'Cards',
    order: 2,
    skeletonRows: 3,
  },
  [CompanyPlanGroup.Invoices]: {
    title: 'Invoices',
    order: 3,
    skeletonRows: 1,
  },
};
