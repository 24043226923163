export enum CounterpartyBusinessType {
  Individual = 'INDIVIDUAL',
  Business = 'BUSINESS',
}

export enum CounterpartyType {
  Merchant = 'MERCHANT',
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR',
}

export enum CounterpartyPaymentMethods {
  Card = 'CARD',
  Ach = 'ACH',
  Wire = 'WIRE',
  WireInternational = 'WIRE_INTERNATIONAL',
}

export enum CounterpartyPaymentAccountType {
  Karta = 'KARTA',
  Bank = 'BANK',
}
