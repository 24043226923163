import { CryptoNetwork, CryptoToken } from '@/enums';

export const CRYPTO_USDT_IMAGE = 'https://static.karta.io/images/usdt-icon.png';
export const CRYPTO_USDC_IMAGE = 'https://static.karta.io/images/usdc-icon.png';
export const CRYPTO_TRON_IMAGE = 'https://static.karta.io/images/tron-icon.png';
export const CRYPTO_ETHEREUM_IMAGE =
  'https://static.karta.io/images/ethereum-icon.png';

export const CryptoDefaults = {
  tokenCode: CryptoToken.Usdt,
  network: CryptoNetwork.Trc20,
  minimumCbDeposit: 50,
  minimumPtDeposit: 1000,
  expectedCbArrivalDescription: 'Up to several hours',
  expectedPtArrivalDescription: 'Up to 3 business days',
};

export const CryptoTypesMap = {
  [CryptoToken.Usdt]: {
    [CryptoNetwork.Trc20]: {
      alertDescription:
        'Send only USDT to this deposit address. Ensure the network is Tron (TRC20).',
    },
    [CryptoNetwork.Erc20]: {
      alertDescription:
        'Send only USDT to this deposit address. Ensure the network is Ethereum (ERC20).',
    },
  },
  [CryptoToken.Usdc]: {
    [CryptoNetwork.Trc20]: {
      alertDescription:
        'Send only USDC to this deposit address. Ensure the network is Tron (TRC20).',
    },
    [CryptoNetwork.Erc20]: {
      alertDescription:
        'Send only USDC to this deposit address. Ensure the network is Ethereum (ERC20).',
    },
  },
} as const;

export const CryptoTokensMap = {
  [CryptoToken.Usdt]: {
    title: CryptoToken.Usdt,
    icon: CRYPTO_USDT_IMAGE,
  },
  [CryptoToken.Usdc]: {
    title: CryptoToken.Usdc,
    icon: CRYPTO_USDC_IMAGE,
  },
} as const;

export const CryptoNetworksMap = {
  [CryptoNetwork.Trc20]: {
    title: `Tron (${CryptoNetwork.Trc20})`,
    icon: CRYPTO_TRON_IMAGE,
  },
  [CryptoNetwork.Erc20]: {
    title: `Ethereum (${CryptoNetwork.Erc20})`,
    icon: CRYPTO_ETHEREUM_IMAGE,
  },
} as const;
