import {
  CompanyAccountType,
  CompanyProviderOutcomePaymentMethod,
} from '@/enums';

export const MOVE_MONEY_VALIDATE_AMOUNT_MESSAGE_DEFAULT =
  'Not enough money for transfer';

export const MoveMoneySendFundsTypesMap = {
  [CompanyProviderOutcomePaymentMethod.OutboundKarta]: {
    title: 'Karta transfer (not available now)',
    description: 'Instant',
    alertTitle: '',
    notifyDescription: '',
    isDisabled: true,
    isFriendlyAvailable: true,
    availableCompanyAccountsTypes: [
      CompanyAccountType.Primary,
      CompanyAccountType.SubAccount,
    ] as CompanyAccountType[],
  },
  [CompanyProviderOutcomePaymentMethod.OutboundAch]: {
    title: 'ACH',
    description: '1-3 business days',
    alertTitle: 'ACH transfer takes up to 3 business days',
    notifyDescription: 'The money should arrive within 3 business days.',
    isDisabled: false,
    isFriendlyAvailable: false,
    availableCompanyAccountsTypes: [
      CompanyAccountType.Primary,
    ] as CompanyAccountType[],
  },
  [CompanyProviderOutcomePaymentMethod.OutboundWire]: {
    title: 'Wire domestic',
    description: '1 business day',
    alertTitle: 'Wire transfer takes up to 1 business days',
    notifyDescription: 'The money should arrive within 1 business day.',
    isDisabled: false,
    isFriendlyAvailable: false,
    availableCompanyAccountsTypes: [
      CompanyAccountType.Primary,
    ] as CompanyAccountType[],
  },
} as const;
