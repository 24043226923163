<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { promiseTimeout } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';

import { useCommonStore, useCoreStore } from '@/stores';
import {
  useAppBaseChecks,
  useCardMarqetaSdk,
  // useCompanyAccountStatementWebsocket,
  useEnv,
  // useWebsocketApi,
  useWebsocketCms,
} from '@/composables';
import { CORE_MAINTENANCE_INDEX_PAGE } from '@/data';

import {
  CoreLocalStorage,
  CoreSessionStorage,
  SocketCmsListener,
} from '@/enums';
import type { CoreAppConfig } from '@/interfaces';

import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutEmpty from '@/layouts/LayoutEmpty.vue';

const { logout } = useAuth0();
const route = useRoute();
const router = useRouter();
const coreStore = useCoreStore();
const commonStore = useCommonStore();
const techWorksKeyStore = useStorage(
  CoreSessionStorage.TechWorksKey,
  '',
  sessionStorage,
);
const sessionId = useStorage(CoreSessionStorage.SessionId, '', sessionStorage);
const { isProduction, isDemo } = useEnv();

const websocketCms = useWebsocketCms();
// const websocketApi = useWebsocketApi();
// const companyAccountStatementWebsocket = useCompanyAccountStatementWebsocket();
const { install: installMarqetaScript } = useCardMarqetaSdk();

localStorage.setItem('debug', String(!isProduction));

const { checkAppVersion } = useAppBaseChecks();
const { closeAll } = useDialog();

const layout = ref('default');

const updateAppConfig = (data: CoreAppConfig['data']) => {
  commonStore.$patch({
    getStartedSalesSocialsLinks: data.getStartedSalesSocialsLinks,
    contactUsLink: data.contactUsLink,
    showBillingPage: data.showBillingPage,
    isTechWorks: data.isTechWorks,
    techWorksKey: data.techWorksKey,
  });
};

const handleLogout = (event: StorageEvent) => {
  if (event.storageArea !== window.localStorage) return;
  if (
    event.key === CoreLocalStorage.IsAuthenticated &&
    event.newValue === 'false'
  ) {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/auth/logout`,
      },
    });
  }
};

useEventListener(window, 'storage', handleLogout);

/**
 * @todo Вернуть при релизе statements + Добавить проверку isApp
 */
// watch(
//   () => coreStore.isReadyForWork,
//   value => {
//     if (!value) {
//       websocketApi.disconnect();
//       companyAccountStatementWebsocket.destroy();
//       return;
//     }

//     websocketApi.createConnection();
//     companyAccountStatementWebsocket.init();
//   },
//   { immediate: true },
// );

watch(
  () => coreStore.isGlobalLoaderShow,
  async newValue => {
    if (newValue) {
      document
        ?.querySelector('.app-loader')
        ?.classList.remove('app-loader_hide');
      return;
    }

    /**
     * Небольшой костыль для исправления переключения лейаутов
     * с Empty на Default
     * @todo проработать тему с Suspense https://vuejs.org/guide/built-ins/suspense.html
     */
    await promiseTimeout(300);
    document?.querySelector('.app-loader')?.classList.add('app-loader_hide');
  },
  { immediate: true },
);

watch(
  () => commonStore.isTechWorks,
  isTechWorks => {
    if (!isTechWorks) {
      window.location.href = '/';
      return;
    }

    if (techWorksKeyStore.value === commonStore.techWorksKey) return;

    closeAll();
    router.push({ name: CORE_MAINTENANCE_INDEX_PAGE.name });
  },
  { flush: 'post' },
);

watch(
  () => route.meta,
  newValue => {
    layout.value = (newValue?.layout as string) ?? 'default';
  },
  { immediate: true },
);

watch(
  () => coreStore.hasMarqetaPaymentService,
  newValue => {
    if (isDemo || !newValue) return;

    installMarqetaScript();
  },
  { immediate: true },
);

onMounted(async () => {
  if (!sessionId.value) sessionId.value = uuidv4();
  if (isDemo) return;

  websocketCms.on(SocketCmsListener.AppConfigCreateOrUpdate, updateAppConfig);

  await promiseTimeout(2 * 60 * 1000);
  useIntervalFn(checkAppVersion, 10 * 60 * 1000, {
    immediateCallback: true,
  });
});

onBeforeUnmount(() => {
  websocketCms.off(SocketCmsListener.AppConfigCreateOrUpdate, updateAppConfig);
  websocketCms.disconnect();
});
/**
 * @TODO прокидывать тип кнопки в LayoutEmpty
 */
</script>

<template>
  <LayoutDefault v-if="layout === 'default'" />
  <LayoutEmpty
    v-if="layout === 'empty'"
    :isSignOutHidden="route.meta?.props?.isSignOutHidden"
  />

  <StagingManagementRoot v-if="!isDemo && !isProduction" />
</template>

<style lang="scss">
body {
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-base);
  color: var(--color-base-black);
}

@include breakpoint('sm') {
  body {
    --base-page-header-height: 80px;
    --base-index-page-header-height: 80px;
  }
}

@include breakpoint('md') {
  body {
    --the-header-height: 0px;
  }
}

.mq-notification {
  right: var(--spacing-04) !important;
  bottom: var(--spacing-04) !important;
  left: auto !important;
  z-index: 2000 !important;

  padding: var(--ui-toast-padding-y) var(--spacing-06) var(--ui-toast-padding-y)
    var(--ui-toast-padding-x) !important;
  background-color: var(--ui-toast-background-color) !important;
  border: var(--ui-toast-border) !important;
  border-radius: var(--ui-toast-border-radius) !important;
  box-shadow: var(--ui-toast-box-shadow) !important;

  font-family: var(--font-family-base) !important;
  font-style: var(--font-style-base) !important;
  font-size: 14px !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: 20px !important;
  letter-spacing: normal !important;
  color: var(--ui-toast-message-color) !important;
}

.ui-toast_message {
  --ui-toast-max-width: calc(100% - 32px);
  --ui-toast-width: max-content;
  --ui-toast-padding-y: var(--spacing-03);
}
</style>
