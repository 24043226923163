import { UiColor } from '@karta.io/ui-components';

import { CompanyUserRole, CompanyUserStatus, FilterType } from '@/enums';
import type { CompanyUserFilters, FiltersConfig } from '@/interfaces';

export const CompanyUserStatusesMap = {
  [CompanyUserStatus.Active]: {
    color: UiColor.Primary,
    label: 'Active',
    visible: false,
  },
  [CompanyUserStatus.Invited]: {
    color: UiColor.Secondary,
    label: 'Pending',
    visible: true,
  },
  [CompanyUserStatus.Blocked]: {
    color: UiColor.Primary,
    label: 'Locked',
    visible: true,
  },
  [CompanyUserStatus.InviteExpired]: {
    color: UiColor.Error,
    label: 'Invite expired',
    visible: true,
  },
  [CompanyUserStatus.InviteRejected]: {
    color: UiColor.Error,
    label: 'Rejected',
    visible: true,
  },
} as const;

export const CompanyUserRolesMap = {
  [CompanyUserRole.Owner]: {
    title: 'Owner',
    description:
      'Superpowered with full account and budgets access. Fine-tunes teams and cards and invites others to join.',
    disabled: true,
  },
  [CompanyUserRole.Employee]: {
    title: 'Employee',
    description:
      'Employees can create cards and review their budgets but cannot access account balances or invite teammates.',
    disabled: false,
  },
  [CompanyUserRole.Bookkeeper]: {
    title: 'Bookkeeper',
    description:
      'Fueled by “view all” access with limited action permissions, bookkeepers create cards and can work as budget managers.',
    disabled: false,
  },
  [CompanyUserRole.Admin]: {
    title: 'Admin',
    description:
      'Superpowered with full account and budget access, admins create budgets and cards, make wire/ACH transfers, and invite teammates.',
    disabled: false,
  },
} as const;

export const CompanyUsersFiltersConfig: FiltersConfig<CompanyUserFilters> = {
  statuses: {
    type: FilterType.Array,
    possibleValues: Object.values(CompanyUserStatus),
  },
  roles: {
    type: FilterType.Array,
    possibleValues: Object.values(CompanyUserRole),
  },
  pending: {
    type: FilterType.String,
  },
  periodName: {
    type: FilterType.String,
  },
  periodDates: {
    type: FilterType.Array,
  },
} as const;
