<script setup lang="ts">
import {
  UiAvatarSize,
  UiColorVar,
  UiIconValue,
  UiTypography,
} from '@karta.io/ui-components';

import { KARTA_PRIMARY_ACCOUNT_IMAGE, KARTA_SUB_ACCOUNT_IMAGE } from '@/data';
import { sliceString } from '@/helpers';

import {
  CompanyAccountType,
  CounterpartyPaymentAccountType,
  Currency,
} from '@/enums';
import type { CompanyAccount } from '@/interfaces';

interface Props {
  companyAccount: CompanyAccount;
  loading?: boolean;
  size?: UiAvatarSize;
  paymentAccountType?: CounterpartyPaymentAccountType;
}

defineOptions({ name: 'CompanyAccountBlock' });

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  size: UiAvatarSize.Lg,
  paymentAccountType: CounterpartyPaymentAccountType.Bank,
});

const cn = useClassName('company-account-block');

const isReady = computed(() => !props.loading && props.companyAccount);

const avatar = computed(() => {
  return props.companyAccount?.type === CompanyAccountType.Primary
    ? KARTA_PRIMARY_ACCOUNT_IMAGE
    : KARTA_SUB_ACCOUNT_IMAGE;
});

const accountNumber = computed(() =>
  props.paymentAccountType === CounterpartyPaymentAccountType.Karta
    ? sliceString(props?.companyAccount?.kartaId)
    : sliceString(props?.companyAccount?.accountNumber),
);

const accountBalance = computed(() => {
  const { companyAccount } = props;
  if (!companyAccount) return null;

  return formatMoney(
    companyAccount.balance,
    companyAccount.currency || Currency.Usd,
  );
});

const typography = computed(() =>
  props.size === UiAvatarSize.Lg
    ? UiTypography.TextXlMedium
    : UiTypography.TextSmMedium,
);
const typographyBrSm = computed(() =>
  props.size === UiAvatarSize.Lg
    ? UiTypography.DisplayXsMedium
    : UiTypography.TextSmMedium,
);
</script>

<template>
  <div :class="[cn.b(), cn.mv('size', props.size)]">
    <AppAvatar
      :loading="props.loading && !props.companyAccount"
      :size="props.size"
      :src="avatar"
    >
      <template #placeholder>
        <UiIcon :value="UiIconValue.Library" />
      </template>
    </AppAvatar>

    <div style="flex-grow: 1">
      <div :class="cn.e('name-container')">
        <AppText
          :loading="!isReady"
          skeletonWidth="40%"
          textEllipsis="100%"
          :typography="typography"
          :typographyBrSm="typographyBrSm"
        >
          {{ props?.companyAccount?.name }}
        </AppText>

        <AppText
          :loading="!isReady"
          skeletonWidth="40%"
          :typography="typography"
          :typographyBrSm="typographyBrSm"
          style="white-space: nowrap"
        >
          {{ accountNumber }}
        </AppText>
      </div>

      <AppText
        :loading="!isReady"
        skeletonWidth="40%"
        :typography="UiTypography.TextXsMedium"
        :color="UiColorVar.Secondary500"
      >
        {{ accountBalance }}
      </AppText>
    </div>
  </div>
</template>

<style lang="scss">
.company-account-block {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: var(--spacing-03);

  &_size_lg {
    grid-column-gap: var(--spacing-04);
  }

  &__name-container {
    display: grid;
    grid-template-columns: minmax(0, auto) 1fr;
    grid-column-gap: var(--spacing-01);
  }
}
</style>
