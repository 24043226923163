<script lang="ts" setup>
import type { UiTableColumnProp } from '@/interfaces';

import TableCellSkeletonDefault from '@/components/table/TableCellSkeletonDefault.vue';

interface Props {
  column: UiTableColumnProp;
}

defineOptions({ name: 'TableCellSkeleton' });

const props = defineProps<Props>();

const render = () => {
  if (!props.column?.cellSkeleton?.component) {
    return h(TableCellSkeletonDefault, {
      align: props.column.styles?.align ?? 'left',
    });
  }

  return h(props.column.cellSkeleton.component, {
    ...(props.column?.cellSkeleton?.props ?? {}),
  });
};
</script>

<template>
  <render />
</template>
